@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');

.Root {
  font-family: Karla;
}

.Root button, .Root div, .Root span {
  font-family: Karla;
}

