/* @import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;700;800&family=Roboto+Mono:wght@300;400;600&display=swap'); */

.shopify-buy__btn-wrapper {
  width: 80%;
}

.shopify-buy__btn {
  background-image: linear-gradient(7deg, rgba(255,255,0,1) -50%, rgba(253,0,255,1) 150%);
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1em;
  border: none;
  width: 100%;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  background-position: 50% 50%;
  background-size: 200% 200%;
  transition: background-position 500ms ease;
  cursor: pointer;
}

.shopify-buy__btn:hover {
  /* animation: slide-gradient 1s ease forwards; */
  background-position: 100% 50%;
  /* background-image: linear-gradient(187deg, rgba(255,255,0,1) -100%, rgba(253,0,255,1) 200%); */
  
}

@keyframes slide-gradient {
  0% {
    background-position: 0 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.shopify-buy__product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.shopify-buy-frame--product:nth-child(4n+1) {
  background-color: #fff6e8;
}

.shopify-buy-frame--product:nth-child(4n+2) {
  background-color: #fff3fb;
}

.shopify-buy__product__variant-img {
  width: 100%;
  border-radius: 4px;
}

.shopify-buy__product__price {
  font-family: Karla, sans-serif;
  margin: 10px 0;
}

.shopify-buy__product__title, .shopify-buy__product__price {
  width: 100%;
  text-align: center;
  color: #4a4a4a;
  display: block;
}

.shopify-buy__product__title {
  font-family: 'Roboto Mono', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 1.2em;
  margin-bottom: 10px;
}