.Root {
  height: 60px;
  font-weight: bold;
  box-shadow: 2px 2px 4px 2px #e7e7e7;
  padding-right: 20px;
}

.Logo {
  padding: 10px;
}

.Link {
  width: 100px;
  text-decoration: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
  font-family: 'Roboto Mono', sans-serif;
  letter-spacing: 0.15em;
  color: #4a4a4a;
}

.LinkActive {
  background-image: linear-gradient(7deg, rgba(255,255,0,1) -0%, rgba(253,0,255,1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* border-image: linear-gradient(7deg, rgba(255,255,0,1) -50%, rgba(253,0,255,1) 75%);
  border-image-slice: 1; */
}
  

@media screen and (min-width: 700px) {
  .Root {
    height: 80px;
    display: flex;
    justify-content: space-between;
  }

  .Links {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
}
