.Root {
  width: 100%;
  /* box-shadow: 2px 2px 4px 2px #e7e7e7; */
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.Row {
  display: block;
}

.Title, .Button {
  font-family: 'Roboto Mono', sans-serif;
  text-transform: uppercase;
  color: #4a4a4a;
  font-weight: 300;
  letter-spacing: 3px;
}

.Title {
  font-size: 1.2em;
  margin: 0;
  text-align: center;
  font-weight: 400;
}


.Button {
  height: 50px;
  background-color: transparent;
  font-size: 1em;
  border: none;
  cursor: pointer;
}