@import url(https://fonts.googleapis.com/css2?family=Karla:wght@300;400;700;800&family=Roboto+Mono:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: Karla, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.CategoryPanel_Root__27F_Y {
  width: 100%;
  /* box-shadow: 2px 2px 4px 2px #e7e7e7; */
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.CategoryPanel_Row__24wjX {
  display: block;
}

.CategoryPanel_Title__2mwF1, .CategoryPanel_Button__2PRSb {
  font-family: 'Roboto Mono', sans-serif;
  text-transform: uppercase;
  color: #4a4a4a;
  font-weight: 300;
  letter-spacing: 3px;
}

.CategoryPanel_Title__2mwF1 {
  font-size: 1.2em;
  margin: 0;
  text-align: center;
  font-weight: 400;
}


.CategoryPanel_Button__2PRSb {
  height: 50px;
  background-color: transparent;
  font-size: 1em;
  border: none;
  cursor: pointer;
}
.Product_Root__2ybm9 {
  font-family: Karla;
}

.Product_Root__2ybm9 button, .Product_Root__2ybm9 div, .Product_Root__2ybm9 span {
  font-family: Karla;
}


/* @import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;700;800&family=Roboto+Mono:wght@300;400;600&display=swap'); */

.shopify-buy__btn-wrapper {
  width: 80%;
}

.shopify-buy__btn {
  background-image: linear-gradient(7deg, rgba(255,255,0,1) -50%, rgba(253,0,255,1) 150%);
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1em;
  border: none;
  width: 100%;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  background-position: 50% 50%;
  background-size: 200% 200%;
  transition: background-position 500ms ease;
  cursor: pointer;
}

.shopify-buy__btn:hover {
  /* animation: slide-gradient 1s ease forwards; */
  background-position: 100% 50%;
  /* background-image: linear-gradient(187deg, rgba(255,255,0,1) -100%, rgba(253,0,255,1) 200%); */
  
}

@keyframes slide-gradient {
  0% {
    background-position: 0 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.shopify-buy__product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.shopify-buy-frame--product:nth-child(4n+1) {
  background-color: #fff6e8;
}

.shopify-buy-frame--product:nth-child(4n+2) {
  background-color: #fff3fb;
}

.shopify-buy__product__variant-img {
  width: 100%;
  border-radius: 4px;
}

.shopify-buy__product__price {
  font-family: Karla, sans-serif;
  margin: 10px 0;
}

.shopify-buy__product__title, .shopify-buy__product__price {
  width: 100%;
  text-align: center;
  color: #4a4a4a;
  display: block;
}

.shopify-buy__product__title {
  font-family: 'Roboto Mono', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 1.2em;
  margin-bottom: 10px;
}
.ProductList_Root__1Jv-X {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  gap: 20px;
}

@media screen and (min-width: 700px) {
  .Shop_Root__3rx4K {
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
  }
}
.Navbar_Root__1c0Ne {
  height: 60px;
  font-weight: bold;
  box-shadow: 2px 2px 4px 2px #e7e7e7;
  padding-right: 20px;
}

.Navbar_Logo__1m6yY {
  padding: 10px;
}

.Navbar_Link__3OsLu {
  width: 100px;
  text-decoration: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
  font-family: 'Roboto Mono', sans-serif;
  letter-spacing: 0.15em;
  color: #4a4a4a;
}

.Navbar_LinkActive__2u9X4 {
  background-image: linear-gradient(7deg, rgba(255,255,0,1) -0%, rgba(253,0,255,1) 100%);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  /* border-image: linear-gradient(7deg, rgba(255,255,0,1) -50%, rgba(253,0,255,1) 75%);
  border-image-slice: 1; */
}
  

@media screen and (min-width: 700px) {
  .Navbar_Root__1c0Ne {
    height: 80px;
    display: flex;
    justify-content: space-between;
  }

  .Navbar_Links__y8xxA {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
}

